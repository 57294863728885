import {
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FileUpload} from "primeng/fileupload";
import {FileService} from "../../support/file.service";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";

@Component({
    template: `
        <form (keydown.enter)="$event.preventDefault()"  [formGroup]="form">
            <p-fileUpload #fileUploader name="file[]" accept="image/*" (onUpload)="onUpload($event.files)"
                          [customUpload]="true"
                          [multiple]="true" (onSelect)="onUpload($event.files)" [showUploadButton]="false"
                          [showCancelButton]="false" [auto]="true" class="w-full">
                <ng-template pTemplate="content">
                    <div class="w-full py-3" style="cursor: copy"
                         (click)="fileUploader.advancedFileInput.nativeElement.click()">
                        <div *ngIf="!uploadedFiles.length"
                             class="h-full flex flex-column justify-content-center align-items-center">
                            <i class="pi pi-upload text-900 text-2xl mb-3"></i>
                            <span class="font-bold text-900 text-xl mb-3">{{'btn.budgetUploadFiles' | translate}}</span>
                            <span class="font-medium text-600 text-md text-center">{{'btn.budgetDropFiles' | translate}}</span>
                        </div>
                        <div class="flex flex-wrap gap-5" *ngIf="uploadedFiles.length">
                            <div *ngFor="let file of uploadedFiles; let i = index;"
                                 class="h-full relative w-7rem h-7rem border-3 border-transparent border-round hover:bg-primary transition-duration-100 cursor-auto"
                                 (mouseenter)="onImageMouseOver(file)" (mouseleave)="onImageMouseLeave(file)"
                                 style="padding: 1px;">
                                <img [src]="file.objectURL" [alt]="file.name"
                                     class="w-full h-full border-round shadow-2">
                                <button [id]="file.name" #buttonEl pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center cursor-pointer"
                                        style="top: -10px; right: -10px; display: none;"
                                        (click)="removeImage($event, file)"></button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-fileUpload>

            <p-fileUpload
                name="file"
                mode="basic"
                [auto]="true"
                url="your-server-endpoint"
                (onUpload)="onUpload($event)"
                [customUpload]="true"
                [chooseLabel]="'btn.upload' | translate"
                multiple="multiple" accept="image/*" maxFileSize="3000000">
                (uploadHandler)="onUpload($event)">
            </p-fileUpload>

<!--            <p-fileUpload name="file[]" customUpload="true"-->
<!--                          (uploadHandler)="onUpload($event.files)"-->
<!--                          mode="basic"-->
<!--                          [auto]="true"-->
<!--                          [chooseLabel]="'btn.upload' | translate"-->
<!--                          multiple="multiple" accept="image/*" maxFileSize="3000000">-->
<!--            </p-fileUpload>-->
        </form>
    `,
    selector: 'app-upload',
    styles: [`
        .p-fileupload-choose {
            display: none !important;
        }

        .p-fileupload .p-fileupload-buttonbar{
            border: 0;
        }

        .p-fileupload .p-fileupload-content{
            -moz-border-radius: 8px;
            -webkit-border-radius: 8px;
            padding: 0
        }
    `],
    encapsulation: ViewEncapsulation.None
})
export class UploadComponent implements OnInit {
    @Input() form: FormGroup
    @ViewChild(FileUpload) fileUpload: FileUpload;
    @ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;
    uploadedFiles: any[] = [];

    constructor(private fileService: FileService, private msgService: MessageService, private translate: TranslateService) {
    }

    ngOnInit(): void {
    }

    onUploadOld(event: any): void {
        console.log('-->>> onUpload');
        if (event && event.files) {
            console.log('Uploaded Files:', event.files);
            event.files.forEach((file: File) => {
                console.log(`File Name: ${file.name}, Size: ${file.size}, Type: ${file.type}`);
            });
        } else {
            console.log('No files uploaded.');
        }

        const formData = new FormData();
        for (let file of event.files) {
            formData.append('files', file, file.name);
        }

        this.fileService.uploadImage(formData).subscribe({
            next: response => {
                if(response.body){
                    response.body.images.forEach(img => this.form.controls.images.value.push(img));
                    this.msgService.add({
                        severity: 'info', life: 7000,
                        detail: this.translate.instant('msg.upload-success')
                    });
                    this.fileUpload.clear();
                }
            }, error: error => {
                this.msgService.add({
                    severity: 'error',
                    summary: this.translate.instant('error.header'),
                    detail: error.message
                });
            }
        });
    }

    onUpload(event: any): void {
        console.log('-->>> onUpload');

        if (event?.files) {
            console.log('Uploaded Files:', event.files);
            event.files.forEach((file: File) => {
                console.log(`File Name: ${file.name}, Size: ${file.size}, Type: ${file.type}`);
            });

            const formData = new FormData();
            event.files.forEach((file: File) => formData.append('files', file, file.name));

            // Upload the files using your service
            this.fileService.uploadImage(formData).subscribe({
                next: response => {
                    if (response.body?.images) {
                        // Update the uploadedFiles array with the new images
                        this.uploadedFiles = [...this.uploadedFiles, ...response.body.images];

                        this.msgService.add({
                            severity: 'info',
                            life: 7000,
                            detail: this.translate.instant('msg.upload-success'),
                        });

                        this.fileUpload.clear(); // Clear the file upload input
                    }
                },
                error: error => {
                    this.msgService.add({
                        severity: 'error',
                        summary: this.translate.instant('error.header'),
                        detail: error.message,
                    });
                },
            });
        } else {
            console.log('No files uploaded.');
        }
    }

    onImageMouseOver(file: any) {
        this.buttonEl.toArray().forEach(el => {
            el.nativeElement.id === file.name ? el.nativeElement.style.display = 'flex' : null;
        })
    }

    onImageMouseLeave(file: any) {
        this.buttonEl.toArray().forEach(el => {
            el.nativeElement.id === file.name ? el.nativeElement.style.display = 'none' : null;
        })
    }

    removeImage(event: Event, file: any) {
        event.stopPropagation();
        this.uploadedFiles = this.uploadedFiles.filter(i => i !== file);
    }
}
