import {Component, OnInit} from '@angular/core';
import {AuthService} from '../support/auth.service';
import {Role} from '../enums/role'

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(
        private authService: AuthService,
    )
        {
    }

    ngOnInit() {
        // Initialize the basic menu items
        let items = [
            {
                label: 'menu.welcome',
                icon: 'fas fa-handshake',
                routerLink: ['/user/welcome'],
            },
            {
                label: 'menu.articles',
                icon: 'fas fa-newspaper',
                routerLink: ['/article/article-search'],
            },
            {
                label: 'menu.alerts',
                icon: 'fas fa-bullhorn',
                routerLink: ['/alert/alert-search'],
            },
            {
                label: 'menu.terms',
                icon: 'fas fa-gavel',
                routerLink: ['/user/user-terms'],
            }
        ];

        const complaintsMenuItem = {
            label: 'menu.complaints',
            icon: 'fas fa-tools',
            routerLink: ['/complaint/complaint-search']
        };

        const budgetsMenuItem = {
            label: 'menu.budgets',
            icon: 'fas fa-chart-line',
            routerLink: ['/budget/budget-search']
        };

        const usersMenuItem = {
            label: 'menu.users',
            icon: 'fas fa-user-cog',
            routerLink: ['/user/user-search']
        };

        const reportsMenuItem = {
            label: 'menu.report',
            icon:'fas fa-flag',
            routerLink:['/report/reports']
        };

        const deleteUSerMenuItem = {
            label: 'menu.request_delete',
            icon: 'fa fa-trash',
            routerLink: ['/user/delete-account']
        };


        if (this.authService.canSeeComplaints()) {
            if (items.length > 2) {
                items.splice(2, 0, complaintsMenuItem);
            } else {
                items.splice(0, 0, complaintsMenuItem);
            }
        }

        if (this.authService.isJustUser()) {
            // if (items.length > 2) {
            //     items.splice(2, 0, budgetsMenuItem);
            // } else {
            //     items.splice(0, 0, budgetsMenuItem);
            // }
            items.push(budgetsMenuItem);
        }

        if (this.authService.isMunicipalityAdmin()) {
            items.push(usersMenuItem);
            items.push(budgetsMenuItem);
        }
        if (this.authService.isModerator()) {
            items.push(reportsMenuItem);
        }

        this.model = [
            {
                label: 'Menu',
                icon: 'pi pi-home',
                items: items
            }
        ];
    }

}
