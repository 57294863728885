import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Router} from "@angular/router";
import {Role} from "../enums/role";
import {StorageService} from "./storage.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {map} from "rxjs/operators";
import {GenericApiService} from "../components/generics/generic-api.service";
import {User} from "../model/user";
import {environment} from "../../environments/environment";
import {Municipality} from "../model/municipality";
@Injectable()
export class AuthService extends GenericApiService<any> {
    constructor(protected http: HttpClient,
                private router: Router,
                private storageService: StorageService,
                private jwtHelperService: JwtHelperService) {
        super(http);
    }

    hasAnyAuthority(authorities: Role[] | Role): boolean {
        const userAuthorities: Role[] = this.userRoles;
        if (!userAuthorities) {
            return false;
        }
        if (!Array.isArray(authorities)) {
            authorities = [authorities];
        }

        return userAuthorities.some((authority: Role) => authorities.includes(authority));
    }
    get isAuthenticated(): boolean {
        const token = localStorage.getItem(environment.LOCAL_STORAGE_TOKEN);
        return token && !this.jwtHelperService.isTokenExpired(token);
    }
    get userRoles(): Role[] {
        const token = localStorage.getItem(environment.LOCAL_STORAGE_TOKEN);
        if (!token) {
            return [];
        }
        if ( this.userDetails?.roles )
            return this.userDetails.roles;

        if  (this.userDetails) {
            const roles = this.userDetails['roles'];
        }
        return [];
    }
    canSeeComplaints(): boolean {
        if (!this.isAuthenticated) {
            return false;
        }
        const userRoles = this.userRoles;
        return userRoles.some(role => role === Role.MUNICIPALITY_ADMIN || role === Role.COMPLAINT_ADMINISTRATOR || role === Role.USER );
    }
    isComplaintAdmin(): boolean {
        if (!this.isAuthenticated) {
            return false;
        }
        const userRoles = this.userRoles;
        return userRoles.some(role => role === Role.MUNICIPALITY_ADMIN || role === Role.COMPLAINT_ADMINISTRATOR );
    }
    isAlertAdmin(): boolean {
        if (!this.isAuthenticated) {
            return false;
        }
        const userRoles = this.userRoles;
        return userRoles.some(role => role === Role.MUNICIPALITY_ADMIN || role === Role.ALERT_ADMINISTRATOR );
    }

    isMunicipalityAdmin(): boolean {
        if (!this.isAuthenticated) {
            return false;
        }
        const userRoles = this.userRoles;
        console.log("userRoles", userRoles);

        return userRoles.some(role => role === Role.MUNICIPALITY_ADMIN || role === Role.SUPER_ADMIN );
    }

    isModerator(): boolean {
        if (!this.isAuthenticated) {
            return false;
        }
        const userRoles = this.userRoles;
        return userRoles.some(role => role === Role.ADMIN_MODERATOR || Role.MUNICIPALITY_ADMIN || role === Role.SUPER_ADMIN );
    }


    getMunicipality(): Municipality | null {
        if (!this.isAuthenticated) {
            return null;
        }
        const userDetails = this.userDetails;
        if (userDetails && userDetails.municipality) {
            return userDetails.municipality;
        }
        return null;
    }

    isJustUser(): boolean {
        if (!this.isAuthenticated) {
            return false;
        }
        const userRoles = this.userRoles;
        return userRoles.includes(Role.USER);
    }
    login(authDetails: any): Observable<string> {
        const options = {headers: new HttpHeaders(), params: new HttpParams(), responseType: 'text'} as any;
        const body = {email: authDetails.username, password: authDetails.password}
        return this.http.post('/komuniti-api/account/authenticate', body, options)
            .pipe(map(res => this.parse(res)));
    }

    forgetPassword(email: string): Observable<any> {
        // Construct the URL for the forgot password endpoint
        const url = `/komuniti-api//account/forgot-password/${encodeURIComponent(email)}`;
        console.log("forgetPassword: ", url);
        return this.http.get(url);
    }

    fetchUserDetails(): Observable<string> {
        return this.http.post('/komuniti-api/account/me', {})
            .pipe(map(res => this.parse(res)));
    }
    get userDetails(): User {
        const token = localStorage.getItem(environment.LOCAL_STORAGE_TOKEN);
        if (!token || this.jwtHelperService.isTokenExpired(token)) {
            return null;
        }
        return this.storageService.getValue(environment.USER_DETAILS) ?? null;
    }
    getJwtToken() {
        return localStorage.getItem(environment.LOCAL_STORAGE_TOKEN);
    }
    logout() {
        localStorage.removeItem(environment.LOCAL_STORAGE_TOKEN);
        this.storageService.removeValue(environment.USER_DETAILS);
        this.router.navigate(['/'])
    }
    deleteAccount(email: string): Observable<string> {
        const options = {headers: new HttpHeaders(), params: new HttpParams(), responseType: 'text'} as any;
        const body = {email: email}
        return this.http.post('/komuniti-api/account/delete-my-account', body, options)
            .pipe(map(res => this.parse(res)));
    }
    get chatAdminRoles() {
        return [Role.SUPER_ADMIN, Role.MUNICIPALITY_ADMIN, Role.ARTICLE_ADMINISTRATOR];
    }



}
